<template>
    <div class="artist_photo">
        <v-progress-circular v-if="changeAvatarLoader" :size="160" :width="3" style="z-index: 1;" color="#20C4F5" indeterminate></v-progress-circular>

        <div v-if="isLoading || !avatar.loaded" class="skeleton-loader"></div>

        <v-img :src="`${avatar.small}?cache=${avatar.key}`"
             v-bind:srcset="avatar.srcset ? `${avatar.small}?cache=${avatar.key} 1x, ${avatar.srcset}?cache=${avatar.key} 2x` : null"
             loading="lazy"
             @load="avatarLoaded"
             :class="{'default-image': isImageDefault, 'show': avatar.loaded, 'hidden': isLoading || !avatar.loaded}"
             @click="$refs['modal-image'].show()"
             alt="Artist profile avatar">
        </v-img>

        <button v-ripple v-if="isAdmin" type="button" class="change_ava" @click="changeAva">
            <span class="material-icons">photo_camera</span>
        </button>

        <input type="file" hidden ref="uploadedFile" accept="image/png, image/jpeg" @change="photoUpload">
        <v-dialog
            v-model="clipperDialog"
            width="500">
            <div class="photo_clipper">
                <button class="close_modal" @click="clipperDialog = false"></button>
                <clipper-fixed
                    preview="avatarPreview"
                    ref="clipper"
                    :ratio="1"
                    class="basic lg clipper-fixed"
                    :src="clipperAvatar"
                    bg-color="transparent"
                    :grid="false"
                    shadow="rgba(0,0,0,0.8)"
                    :area="50"
                    :handle-zoom-event="handleZoom"
                    @load="imgAvaLoad"
                    :rotate="0">
                    <div slot="placeholder">No image</div>
                </clipper-fixed>

                <clipper-range v-model="scale" :min="0.5" :max="2"></clipper-range>
                <div class="actions_clipper_photo">
                    <button type="button" class="tetriatary_btn small close_clipper_photo" @click="closeClipperDialog" v-ripple>Close</button>

                    <!-- Upload New Photo -->
<!--                    <input type="file" hidden ref="uploadedFile" accept="image/png, image/jpeg" @change="photoUpload">-->
                    <!-- <button type="button" class="primary_btn small" @click="changeAva()" v-ripple>Upload New Photo</button> -->
                    <button type="button" class="clip_photo primary_btn small" @click="clipImage" v-ripple>Save</button>
                </div>
            </div>
        </v-dialog>

        <modalImage ref="modal-image" :image="`${avatar.original}?cache=${avatar.key}`"/>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {baseUrlToBlob} from "@/utils/baseUrlToBlob";

const ModalImage = () => import("@/components/public/modalImage");

export default {
    name: "artistProfileAvatar",
    components: {
        ModalImage
    },
    props: {
        isAdmin: {
            type: Boolean,
            required: true
        },
        artistID: {
            required: true
        },
        isLoading: Boolean,
    },
    data() {
        return {
            apiUrl: process.env.VUE_APP_API_URL,

            isImageDefault: false,
            avatar: {
                original: '',
                small: '',
                srcset: '',
                loaded: false,
                key: new Date().getTime(),
            },
            uploadFile: '',
            clipperAvatar: '',
            changeAvatarLoader: false,

            clipperDialog: false,
            scale: 0.5,
        }
    },
    watch: {
        scale(val) {
            this.$refs.clipper.setWH$.next(+val);
        },
        ARTIST: {
            handler() {
				if (this.ARTIST && this.ARTIST.avatar) {
					this.getAvatar();
				}
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters(['FILE_URL', 'ARTIST'])
    },
    created() {
        this.getAvatar();
    },
    mounted() {

    },
    methods: {
        ...mapActions(['ADD_FILE', 'CHANGE_ARTIST_DATA', 'GET_ARTIST']),
        getAvatar() {
            this.changeAvatarLoader = true;
            this.avatar.loaded = false;
            
            this.avatar.small = require('@/assets/image/svg/square_avatar.svg');
            this.avatar.original = require('@/assets/image/svg/default_avatar.svg');
            
            if (this.ARTIST.avatar && this.ARTIST.avatar.thumbnail) {
                this.avatar.small = this.ARTIST.avatar.thumbnail['160x160'];
                this.avatar.srcset = this.ARTIST.avatar.thumbnail['160x160@2x'];
                this.avatar.original = this.ARTIST.avatar.thumbnail['1080x1080'];
            }

            this.isImageDefault = !this.ARTIST.avatar || !this.ARTIST.avatar.thumbnail;

            if (this.isImageDefault) {
                this.avatar.loaded = true;
            }

            this.changeAvatarLoader = false;
            this.avatar.key = new Date().getTime();
        },
        avatarLoaded() {
            this.avatar.loaded = true;
        },
        imgAvaLoad() {
            this.scale = 0.5;
            this.$refs.clipper.setWH$.next(0.5);
        },
        handleZoom(scale) {
            if (!isNaN(scale)) {
                const limitedScale = Math.max(Math.min(2, scale), 0.1);
                this.scale = limitedScale;
                return limitedScale;
            }
        },

        photoUpload() {
            this.uploadedFile = this.$refs.uploadedFile.files[0];
            this.clipperDialog = true;
            this.scale = 0.5;

            // pre load image url
            const fileReader = new FileReader();
            fileReader.addEventListener('load', () => {
                this.clipperAvatar = fileReader.result;
            });
            fileReader.readAsDataURL(this.uploadedFile);
        },
        changeAva() {
            this.$refs.uploadedFile.click();
            if (this.uploadedFile) {
                this.clipperDialog = true;
            }
        },

        clipImage() {
            this.changeAvatarLoader = true;

            const canvas = this.$refs.clipper.clip();
            let dataURL = canvas.toDataURL("image/png", 1);
            this.uploadedFile = baseUrlToBlob(dataURL);

            this.clipperDialog = false;

            const artistData = {
                id: this.artistID ?? this.ARTIST.id
            }

            let formData = new FormData();
            formData.append('file', this.uploadedFile);
            formData.append('type', 'artist');
            formData.append('entity_id', artistData.id);

            this.ADD_FILE(formData)
                .then(() => {
                    artistData.avatar = this.FILE_URL;
                })
                .catch(err => console.log(`ADD_FILE (artist), ${err}`))
                .finally(() => {
                    if (artistData.avatar) {
                        this.CHANGE_ARTIST_DATA(artistData)
                            .then(() => {
                                this.GET_ARTIST(this.artistID ?? this.ARTIST.id)
                                    .then(() => {
                                        const newCacheKey = new Date().getTime();
                                        this.avatar.key = newCacheKey;
                                        this.$emit('avatar-updated', newCacheKey);
                                    })
                                    .catch(err => console.log(`get artist, ${err}`));
                            })
                            .catch(err => console.log(`CHANGE_ARTIST_DATA, ${err}`));
                    }
                    this.changeAvatarLoader = false;
                });
        },
        closeClipperDialog() {
            this.clipperDialog = false;
        },
        refreshAvatar(newCacheKey) {
            this.avatar.key = newCacheKey;
            this.getAvatar();
        },
    }
}
</script>

<style scoped>
.artist_photo {
    position: relative;
    width: 160px;
    height: 160px;
}

.v-image {
    width: 160px !important;
    height: 160px !important;
    border-radius: 50%;
    overflow: hidden;
}

.v-image :deep(.v-image__image) {
    border-radius: 50%;
}

/* Default image specific styling */
.v-image.default-image :deep(.v-image__image) {
    background-color: #10182b !important;
    background-size: 90% !important;
    background-repeat: no-repeat !important;
    border-radius: 50% !important;
}

/* Force background color for default image container */
.v-image.default-image {
    background: #10182b !important;
}

.v-image.show {
    opacity: 1;
}

.skeleton-loader {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background: linear-gradient(90deg, #1a2235 25%, #243049 50%, #1a2235 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}

.hidden {
    display: none;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}
</style>
